<template>
  <v-layout v-if="getPermission('product:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-col md="10" offset-md="1" class="pb-0">
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Spare Part Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                  >Name</label
                >
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100 required"
                  >Serial no</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  label="Name"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.name"
                  :rules="[
                    validateRules.required(productCreate.name, 'Name'),
                    validateRules.minLength(productCreate.name, 'Name', 1),
                    validateRules.maxLength(productCreate.name, 'Name', 100),
                  ]"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Serial no"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.barcode"
                  :rules="[
                    validateRules.required(productCreate.barcode, 'Serial no'),
                    validateRules.minLength(
                      productCreate.barcode,
                      'Serial no',
                      3
                    ),
                    validateRules.maxLength(
                      productCreate.barcode,
                      'Serial no',
                      15
                    ),
                  ]"
                ></v-text-field>
              </div>
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Part Number</label
                >
                <!-- <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  for="serial_number"
                  >Serial Number</label
                > -->
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Category
                </label>
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  label="Part Number"
                  solo
                  flat
                  color="cyan"
                  class="pr-2 width-100"
                  :rules="[
                    validateRules.minLength(
                      productCreate.part_number,
                      'Part Number',
                      1
                    ),
                    validateRules.maxLength(
                      productCreate.part_number,
                      'Part Number',
                      100
                    ),
                  ]"
                  v-model.trim="productCreate.part_number"
                ></v-text-field>
                <!--    <v-text-field
                  id="serial_number"
                  dense
                  filled
                  label="Serial Number"
                  solo
                  flat
                  class="pl-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.serial_number"
                ></v-text-field> -->
                <!--  <pre>{{ categoryList }}</pre> -->
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="categoryList"
                  label="Category"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="manageCategoryDialog = true"
                  item-text="text"
                  item-value="text"
                  class="pr-2 width-100"
                  v-model.trim="productCreate.category"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Category(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Brand</label
                >
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Website URL</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  :items="brandList"
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  label="Brand"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="BrandData"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.brand"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Brand(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  label="Website URL"
                  solo
                  flat
                  class="pr-2 width-100"
                  color="cyan"
                  v-model.trim="productCreate.website_url"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Machine Type</label
                >
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Model</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  :items="machine_types"
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  label="Machine Type"
                  solo
                  flat
                  item-text="name"
                  item-value="id"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.machine_type"
                  v-on:change="filterModels($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Machine Type(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  :items="machine_models"
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  label="Model"
                  solo
                  flat
                  item-text="name"
                  item-value="id"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.machine_model"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Model(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>

              <!-- <div class="d-flex">
                      <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                        >Manufacturer</label
                      >

                      <label class="pl-2 font-weight-700 font-size-16 width-100"
                        >Brand</label
                      >
                    </div>
                    <div class="d-flex">
                      <v-autocomplete
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="manufacturerList"
                        label="Manufacturer"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="manageManufacturerDialog = true"
                        item-text="text"
                         item-value="text"
                        class="pr-2 width-100"
                        v-model.trim="productCreate.manufacturer"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Manufacturer(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                      <v-autocomplete
                        :items="brandList"
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        label="Brand"
                        solo
                        flat
                        append-outer-icon="mdi-cog"
                        v-on:click:append-outer="manageBrandDialog = true"
                        item-text="text"
                         item-value="text"
                        class="pl-2 width-100"
                        v-model.trim="productCreate.brand"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Brand(s) Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </div> -->
            </v-card-text>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Description Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <label class="pl-2 font-weight-700 font-size-16"
                >Description</label
              >
              <v-textarea
                dense
                filled
                label="Description"
                solo
                flat
                row-height="20"
                auto-grow
                color="cyan"
                v-model.trim="productCreate.description"
                :rules="[
                  validateRules.minLength(
                    productCreate.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.description,
                    'Description',
                    1024
                  ),
                ]"
                v-on:keypress="(e) => manageLimitdescr(e)"
                v-on:paste="(e) => onPaste(e)"
              ></v-textarea>
              <v-col md="12" class="text-right"
                >{{
                  productCreate.description
                    ? productCreate.description.length
                    : 0
                }}/200</v-col
              >
            </v-card-text>
          </v-card>

          <br />

          <!-- New -->

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-col md="12" class="py-0">
                <div
                  class="middle-header-background mt-4 mt-7 px-4 d-flex align-center"
                >
                  <!-- <p>
                    Price Information<br />
                    <span>
                      <v-icon small>mdi-progress-question</v-icon> Specify the
                      unit price, selling cost and discount price details for
                      your product</span
                    >
                  </p> -->
                  <v-spacer></v-spacer>
                  <v-btn
                    class="white--text mr-2"
                    depressed
                    color="cyan"
                    tile
                    @click="addPrice()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add New Price
                    <!-- :disabled="isDisable" -->
                  </v-btn>
                </div>
              </v-col>

              <!-- v-if="!uuid && priceList && priceList.length" -->
              <v-col
                md="12"
                v-if="
                  productCreate &&
                  productCreate.priceList &&
                  productCreate.priceList.length
                "
              >
                <v-row class="mt-1">
                  <v-col class="py-0">
                    <label class="btx-label mt-2 required"
                      >Selling Price/Unit Price</label
                    >
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">Discounted Price</label>
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">Start Date</label>
                  </v-col>
                  <v-col class="py-0">
                    <label class="btx-label mt-2">End Date</label>
                  </v-col>
                  <v-col md="1" class="py-0">
                    <label class="btx-label mt-2"></label>
                  </v-col>
                </v-row>
                <template>
                  <v-row
                    v-for="(row, index) in productCreate.priceList"
                    :key="index"
                    class="mb-3"
                  >
                    <v-col class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        solo
                        flat
                        dense
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Selling Price/Unit Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="row.selling_cost"
                        @keyup="discountPriceChange(index)"
                        v-on:change="discountSelling(index)"
                        :rules="[
                          validateRules.required(
                            row.selling_cost,
                            'Selling Price/Unit Price'
                          ),
                        ]"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>

                      <!-- dense
                              filled
                              color="cyan"
                              label="Selling Cost / Unit Cost"
                              solo
                              flat
                              class="pl-2 width-100"
                              :class="{ 'pr-2': !updateMode }"
                              type="number"
                              min="0"
                              max="9999"
                              v-on:keypress="
                                (e) => manageLimit(e, row.selling_cost)
                              "
                              prepend-inner-icon="mdi-currency-usd"
                              v-model.trim="row.selling_cost" -->
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        placeholder="Discount Price"
                        @keyup="discountPriceChange(index)"
                        v-on:change="discountSelling(index)"
                        hide-spin-buttons
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.number="row.discount_price"
                        class="mt-0"
                      ></v-text-field>

                      <!-- dense
                                filled
                                color="cyan"
                                label="Discounted Price"
                                solo
                                flat
                                class="pr-2 width-50"
                                type="number"
                                min="0"
                                max="5"
                                prepend-inner-icon="mdi-currency-usd"
                                v-model.trim="row.discount_price"
                                v-on:keypress="
                                  (e) => manageLimit(e, row.discount_price)
                                " -->
                    </v-col>
                    <v-col class="py-0">
                      <DatePicker
                        hide-details
                        hide-top-margin
                        :disabled="isStartDateDisable(index) || pageLoading"
                        :loading="pageLoading"
                        placeholder="Start Date"
                        :min-date="end_min_date(row.start_date, index)"
                        v-model="row.start_date"
                        :rules="[
                          validateRules.required(row.start_date, 'Start Date'),
                        ]"
                      >
                        <!-- outlined
                            depressed
                            hide-details
                            placeholder="Start Date"
                            v-model.trim="row.start_date"
                            class-name="mt-0"
                            clearable -->
                      </DatePicker>
                      <!-- <DatePicker2
														hide-details
														hide-top-margin
														:disabled="isStartDateDisable(index) || pageLoading"
														:loading="pageLoading"
														placeholder="Start Date"
														:min-date="end_min_date(row.start_date, index)"
														v-model="row.start_date"
														:rules="[vrules.required(row.start_date, 'Start Date')]"
														:class="{
															required: !row.start_date,
														}"
														@change="updateStartDate(row.start_date, index)"
													></DatePicker2> -->
                    </v-col>
                    <v-col class="py-0">
                      <DatePicker
                        hide-details
                        readonly
                        :loading="pageLoading"
                        :disabled="true"
                        hide-top-margin
                        placeholder="End Date"
                        v-model="row.end_date"
                      >
                        <!-- outlined
                            depressed
                            hide-details
                            placeholder="End Date"
                             v-model.trim="row.end_date"
                            class-name="mt-0"
                            clearable -->
                      </DatePicker>

                      <!-- <DatePicker2
														hide-details
														readonly
														:loading="pageLoading"
														:disabled="true"
														hide-top-margin
														placeholder="End Date"
														v-model="row.end_date"
													></DatePicker2> -->
                    </v-col>
                    <v-col md="1" class="py-0">
                      <v-btn
                        v-if="productCreate.priceList.length == index + 1"
                        class="white--text"
                        depressed
                        color="red darken-4"
                        tile
                        @click="removePrice(index, row)"
                        min-width="30"
                        max-width="30"
                        width="30"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>

              <!-- <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Cost Price</label
                >

              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Cost Price"
                  solo
                  flat
                  class="pr-2 width-50"
                  type="number"
                  min="0"
                  max="5"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.purchase_cost"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.purchase_cost)
                  "
                ></v-text-field>
                
              </div> -->
            </v-card-text>
          </v-card>
          <!-- New End -->

          <!-- supplier start -->

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Supplier Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-col md="12" class="py-0">
                <div
                  class="middle-header-background mt-4 mt-7 px-4 d-flex align-center"
                >
                  <p>
                    <span>
                      <v-icon small>mdi-progress-question</v-icon> Specify the
                      supplier, supplier code and price details for your Spare
                      Part</span
                    >
                  </p>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="white--text mr-2"
                    depressed
                    color="cyan"
                    tile
                    @click="addSupplier()"
                  >
                    <v-icon left>mdi-plus</v-icon>
                    Add Supplier
                    <!-- :disabled="isDisable" -->
                  </v-btn>
                </div>
              </v-col>

              <v-col md="12">
                <v-row
                  class="mt-1"
                  v-if="
                    productCreate &&
                    productCreate.suppliers &&
                    productCreate.suppliers.length
                  "
                >
                  <v-col md="5" class="py-0">
                    <label for="supplier fw-500" class="btx-label mt-2 required"
                      >Supplier
                      <!-- <pre>{{ vendorList }}</pre> -->
                    </label>
                  </v-col>
                  <v-col md="3" class="py-0">
                    <label for="supplier-part-number" class="btx-label mt-2"
                      >Supplier Code</label
                    >
                  </v-col>
                  <v-col md="3" class="py-0">
                    <label for="supplier-price" class="btx-label mt-2 required"
                      >Purchasing Price</label
                    >
                  </v-col>
                  <v-col class="py-0">
                    <label for="" class="btx-label mt-2"></label>
                  </v-col>
                </v-row>
                <template>
                  <v-row
                    v-for="(row, index) in productCreate.suppliers"
                    :key="index"
                    class="mb-3"
                  >
                    <v-col md="5" class="py-0">
                      <!-- <v-text-field
                           type="number"
														outlined
                            dense 
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Selling Price/Unit Price"
														prepend-inner-icon="mdi-currency-usd"
														v-model="row.selling_cost" 
														:rules="[
                            validateRules.required(row.selling_cost, 'Selling Price/Unit Price'),
                          ]"
													
														class="mt-0"
                           ></v-text-field> -->

                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="
                          supplierList(
                            productCreate.suppliers[index].supplier_id
                          )
                        "
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Supplier"
                        v-model="productCreate.suppliers[index].supplier_id"
                        :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_id,
                            'Supplier'
                          ),
                        ]"
                        class="mt-0"
                      >
                        <!-- @change="saveSupplierToLocal()" -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Supplier Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-part-number"
                        placeholder="Supplier Code"
                        v-model="productCreate.suppliers[index].part_number"
                        class="mt-0"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-price"
                        placeholder="Purchasing Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="productCreate.suppliers[index].supplier_price"
                        :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_price,
                            'Purchasing Price'
                          ),
                        ]"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>

                    <!-- :rules="[
                          validateRules.required(
                            productCreate.suppliers[index].supplier_price,
                            'Purchasing Price'
                          ),
                        ]" -->
                    <!-- <v-col class="py-0">
                          <DatePicker
                           hide-details
														readonly
														:loading="pageLoading"
														:disabled="true"
														hide-top-margin
														placeholder="End Date"
														v-model="row.end_date"
                            
                          >
                          
                          </DatePicker>


													
												</v-col> -->
                    <v-col md="1" class="py-0">
                      <v-btn
                        class="white--text"
                        depressed
                        color="red darken-4"
                        tile
                        @click="removeSupplier(index, row)"
                        min-width="30"
                        max-width="30"
                        width="30"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-card-text>
          </v-card>

          <!-- supplier end -->

          <v-card
            flat
            class="custom-grey-border remove-border-radius"
            v-if="false"
          >
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Pricing / Quantity Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Selling Cost / Unit Cost</label
                >

                <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Threshold Price</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Selling Cost / Unit Cost"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.selling_cost)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.selling_cost"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Threshold Price"
                  solo
                  flat
                  class="pl-2 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  type="number"
                  min="0"
                  max="9999"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.threshold_price)
                  "
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.threshold_price"
                ></v-text-field>
              </div>

              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Cost Price</label
                >

                <!-- <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Quantity</label
                > -->
              </div>
              <div class="d-flex">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Cost Price"
                  solo
                  flat
                  class="pr-2 width-50"
                  type="number"
                  min="0"
                  max="5"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.purchase_cost"
                  v-on:keypress="
                    (e) => manageLimit(e, productCreate.purchase_cost)
                  "
                ></v-text-field>
                <!--  <v-text-field
                    label="Quantity"
                    dense
                    filled
                    solo
                    flat
                    class="pl-2 width-100"
                    type="number"
                    color="cyan"
                    v-model="productCreate.quantity"
                    v-mask="'####'"
                  ></v-text-field> -->
              </div>

              <div v-if="false" class="d-flex w-50">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100">
                  Commission Rate (%)</label
                >
              </div>
              <div v-if="false" class="d-flex w-50">
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Commission Rate (%)"
                  solo
                  flat
                  class="pr-2 width-100"
                  type="number"
                  min="0"
                  max="9999"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model.trim="productCreate.commission_rate"
                  v-on:keypress="
                    limitDecimal($event, productCreate.commission_rate)
                  "
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <br />

          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Stock Information
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label
                  class="pl-2 font-weight-700 font-size-16 width-50 required"
                  >UOM
                </label>
                <label
                  class="pl-9 font-weight-700 font-size-16 width-50 required"
                  >Initial Quantity</label
                >
                <label class="font-weight-700 font-size-16 width-50"
                  >Purchase Date</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  dense
                  filled
                  color="cyan"
                  item-color="cyan"
                  :items="unitList"
                  label="UOM"
                  solo
                  flat
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="UomData"
                  item-text="text"
                  item-value="text"
                  class="pl-2 width-33"
                  v-model.trim="productCreate.unit"
                  :rules="[validateRules.required(productCreate.unit, 'UOM')]"
                  @change="setLowStockData"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No UOM Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Initial Quantity"
                  solo
                  flat
                  :rules="
                    productCreate.product_type == 'goods'
                      ? [
                          validateRules.required(
                            productCreate.initial_stock,
                            'Initial Quantity'
                          ),
                        ]
                      : []
                  "
                  v-mask="'######'"
                  class="pl-2 width-33"
                  v-model.trim="productCreate.initial_stock"
                ></v-text-field>
                <DatePicker
                  class="pl-2 width-33"
                  solo
                  flat
                  :mandatory="false"
                  :nullable="true"
                  placeholder="Purchase Date"
                  v-model="productCreate.purchase_date"
                  :default-date="productCreate.purchase_date"
                ></DatePicker>
              </div>
              <div class="d-flex">
                <label class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                  >Location</label
                >
              </div>
              <div class="d-flex">
                <v-autocomplete
                  hide-details
                  id="location-equipment"
                  :items="equipment_locations"
                  dense
                  v-model="productCreate.location"
                  flat
                  filled
                  label="Location"
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-text="text"
                  item-value="id"
                  append-outer-icon="mdi-cog"
                  v-on:click:append-outer="manage_location = true"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Location(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </div>
              <!-- <div>
                <label
                  class="pl-2 font-weight-700 font-size-16 width-50"
                  >Purchase Date</label
                >
                <DatePicker
                    class="pl-2 width-50"
                    solo
                    flat
                    :mandatory="false"
                    :nullable="true"
                    placeholder="Purchase Date"
                    v-model="productCreate.activation_date"
                    :min-date="minDate"
                    :default-date="productCreate.activation_date"
                  ></DatePicker>
              </div> -->
            </v-card-text>
          </v-card>
          <br />
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Low stock trigger
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <div class="d-flex">
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >UOM
                </label>
                <label class="pl-2 font-weight-700 font-size-16 width-100"
                  >Quantity</label
                >
              </div>
              <div class="d-flex">
                <v-text-field
                  disabled
                  dense
                  filled
                  color="cyan"
                  label="UOM"
                  solo
                  flat
                  class="pl-2 width-100"
                  v-model.trim="productCreate.low_stock_uom"
                ></v-text-field>
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Quantity"
                  solo
                  flat
                  v-mask="'######'"
                  class="pl-2 width-100"
                  v-model.trim="productCreate.low_stock_quantity"
                ></v-text-field>
              </div>
            </v-card-text>
          </v-card>

          <template>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="UPC"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.upc, 'UPC', 1),
                  validateRules.maxLength(productCreate.upc, 'UPC', 100),
                ]"
                v-model.trim="productCreate.upc"
                class="pr-2 width-100"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                label="SKU"
                solo
                flat
                color="cyan"
                :rules="[
                  validateRules.minLength(productCreate.sku, 'SKU', 1),
                  validateRules.maxLength(productCreate.sku, 'SKU', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.sku"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="HSN Code"
                solo
                flat
                :rules="[
                  validateRules.minLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.hsn_code,
                    'HSN Code',
                    100
                  ),
                ]"
                class="pr-2 width-100"
                v-model.trim="productCreate.hsn_code"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                color="cyan"
                label="EAN"
                solo
                flat
                :rules="[
                  validateRules.minLength(productCreate.ean, 'EAN', 1),
                  validateRules.maxLength(productCreate.ean, 'EAN', 100),
                ]"
                class="pl-2 width-100"
                v-model.trim="productCreate.ean"
              ></v-text-field>
            </div>
            <div class="d-flex">
              <v-text-field
                v-if="false"
                dense
                filled
                label="ISBN"
                solo
                flat
                color="cyan"
                class="pr-2 width-100"
                :rules="[
                  validateRules.minLength(productCreate.isbn, 'ISBN', 1),
                  validateRules.maxLength(productCreate.isbn, 'ISBN', 100),
                ]"
                v-model.trim="productCreate.isbn"
              ></v-text-field>
              <v-text-field
                v-if="false"
                dense
                filled
                label="Part Number"
                solo
                flat
                color="cyan"
                class="pl-2 width-100"
                :rules="[
                  validateRules.minLength(
                    productCreate.part_number,
                    'Part Number',
                    1
                  ),
                  validateRules.maxLength(
                    productCreate.part_number,
                    'Part Number',
                    100
                  ),
                ]"
                v-model.trim="productCreate.part_number"
              ></v-text-field>
            </div>
          </template>
          <div class="d-flex" v-if="false">
            <label class="custom-form-label">Tax Preference</label>
            <v-radio-group
              class="pt-0 mt-0 mb-2"
              dense
              row
              color="cyan"
              v-model.trim="productCreate.taxable"
            >
              <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
              <v-radio
                label="Non-Taxable"
                color="cyan"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </div>

          <template> </template>
          <template>
            <div v-if="$hasWarranty && getPermission('warranty:create')">
              <v-checkbox
                class="mt-0 width-100"
                color="cyan"
                v-model.trim="warrantyActive"
                v-on:change="checkWarrrnty($event)"
              >
                <template v-slot:label>
                  <label class="custom-form-label m-0 width-100"
                    >Warranty</label
                  >
                </template>
              </v-checkbox>
              <!-- <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <template v-for="(warranty, index) in productCreate.warranty">
                  <div class="d-flex" :key="index">
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      color="cyan"
                      label="Duration"
                      solo
                      flat
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      color="cyan"
                      hide-details
                      solo
                      flat
                      item-color="cyan"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      label="Type"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                    <div v-if="false" class="pl-2 width-50">
                      <template v-if="index > 0">
                        <v-btn
                          class="mx-2"
                          color="danger"
                          dark
                          fab
                          small
                          v-on:click="removeWarranty(index, warranty.id)"
                        >
                          <v-icon dark> mdi-minus</v-icon>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          class="mx-2"
                          color="cyan"
                          dark
                          fab
                          small
                          v-on:click="pushWarranty"
                        >
                          <v-icon dark> mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                </template>
              </template> -->
              <template v-if="warrantyActive">
                <div class="d-flex">
                  <label
                    class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                    >Duration</label
                  >

                  <label class="pl-2 font-weight-700 font-size-16 width-100"
                    >Type</label
                  >
                </div>
                <!-- <template v-for="(warranty, index) in productCreate.warranty"> -->
                <div class="d-flex">
                  <v-text-field
                    dense
                    v-mask="'###'"
                    filled
                    color="cyan"
                    label="Duration"
                    solo
                    flat
                    class="pr-2 width-100"
                    v-model.trim="productCreate.warranty_duration"
                  ></v-text-field>
                  <v-select
                    :items="warrantyDurationTypes"
                    dense
                    filled
                    color="cyan"
                    hide-details
                    solo
                    flat
                    item-color="cyan"
                    class="pl-2 pr-2 width-100"
                    v-model.trim="productCreate.warranty_type"
                    label="Type"
                    item-text="text"
                    item-value="value"
                  ></v-select>
                  <div v-if="false" class="pl-2 width-50">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="danger"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index, warranty.id)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </div>
                </div>
                <!--  </template> -->
              </template>
            </div>

            <br />
            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Spare Part Images
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Images
                    <!--  <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 images are not allowed</span>
                    </v-tooltip> -->
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :file-type="true"
                      :attachments="productCreate.product_images"
                      v-on:file:updated="updateImages"
                      acceptFile="image/png, image/jpeg, image/jpg"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
              <v-card-text class="p-6 font-size-16" v-if="false">
                <div v-if="getPermission('attachment:create')">
                  <label class="custom-form-label width-100"
                    >Attachments
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 attachments are not allowed</span>
                    </v-tooltip>
                  </label>
                  <div>
                    <FileTemplate
                      allowUpload
                      isMinDisplay
                      :attachments="productCreate.product_attachments"
                      v-on:file:updated="updateAttachments"
                      :file-type="true"
                    ></FileTemplate>
                  </div>
                </div>
              </v-card-text>
            </v-card>

            <v-card flat class="custom-grey-border remove-border-radius">
              <v-card-title class="headline grey lighten-4">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Attachments
                </h3>
              </v-card-title>
              <v-card-text class="p-6 font-size-16">
                <FileUpload v-model="productCreate.files"> </FileUpload>
              </v-card-text>
            </v-card>
          </template>
        </v-col>
      </perfect-scrollbar>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manageManufacturerDialog"
      :manufacturer="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    ></ManageManufacturer>
    <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
    <ManageEquipmentLocation
      :dialog="manage_location"
      :equipment="equipment_locations"
      v-on:close-dialog="manage_location = false"
      v-on:get-equipment-location="getOptions"
    ></ManageEquipmentLocation>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import DatePicker from "@/view/components/DatePicker";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
import { mapGetters } from "vuex";
import moment from "moment";

import {
  QUERY,
  PATCH,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
// import { find } from "lodash"; map
import { cloneDeep, filter, find } from "lodash";
import FileUpload from "@/view/components/FileUpload";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    ManageEquipmentLocation,
    FileTemplate,
    DatePicker,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    ManageUnit,
    FileUpload,
  },
  data() {
    return {
      equipment_locations: [],
      machine_types: [],
      machine_models: [],
      proDuplicateId: null,
      manage_location: false,
      timeoutLimit: 500,
      pageLoading: false,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      //  supplierList: new Array(),
      vendorList: [],
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      productCreate: {
        name: null,
        machine_type: null,
        machine_model: null,
        location: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        warranty_type: null,
        warranty_duration: 0,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        project_price: null,
        threshold_price: null,
        /*  quantity:1, */
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        attachments: [],
        product_images: [],
        product_attachments: [],
        website_url: null,
        purchase_date: null,
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
        priceList: [
          {
            id: null,
            unit_price: 0,
            selling_price: 0,
            discount_price: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: null,
          },
        ],
        suppliers: [
          {
            id: null,
            uuid: null,
            product_id: null,
            supplier_price: null,
            part_number: null,
          },
        ],
        files: [],
        low_stock_uom: null,
        low_stock_quantity: 0,
      },

      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;

      // this.productCreate.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        this.productCreate.barcode = param.barcode ? param.barcode : null;

        if (this.productCreate.machine_type) {
          this.filterModels(this.productCreate.machine_type);
        }
        // const files = map(this.productCreate.files, (row) => {
        //   return { ...row };
        // });

        // if(files.length){
        //   this.productCreate.files = files;
        // }

        this.$nextTick(() => {
          if (param.warranty_type && param.warranty_duration) {
            this.warrantyActive = true;
          }
        });
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }
      },
    },
    /*  productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        console.log(this.productCreate, "this.productCreate");
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (param && this.lodash.isEmpty(param.warranty)) {
          this.warrantyActive = false;
          this.productCreate.warranty.push({
            id: null,
            value: null,
            field: null,
          });
        }
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }

        this.$nextTick(() => {
          if (
            param.warranty &&
            param.warranty.length &&
            param.warranty[0] &&
            param.warranty[0].value
          ) {
            this.warrantyActive = true;
           
          }
        });
      },
    }, */
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    filterModels(machine = 0) {
      this.machine_models = [];
      this.machine_types.map((row) => {
        if (row.id == machine) {
          this.machine_models = row.subcategory;
        }
      });
    },
    setLowStockData(event) {
      //console.log(event);
      this.productCreate.low_stock_uom = event;
    },
    checkWarrrnty(event) {
      if (!event) {
        this.productCreate.warranty_type = null;
        this.productCreate.warranty_duration = 0;
      } else {
        if (this.updateMode) {
          this.$emit("get:warrantyActive", true);
        }
        this.productCreate.warranty_type = this.productCreate.warranty_type;
        this.productCreate.warranty_duration =
          this.productCreate.warranty_duration;
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.productCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.productCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.productCreate.description &&
        this.productCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },

    addSupplier() {
      this.productCreate.suppliers.push({
        id: null,
        supplier_id: null,
        part_number: null,
        supplier_price: null,
      });
    },
    // removeSupplier(index, data) {
    //   if (data.id) {
    //     const param = { id: data.id };
    //     RemoveSuppliers(this.uuid, param).then(() => {
    //       this.suppliers.splice(index, 1);
    //     });
    //   } else {
    //     this.suppliers.splice(index, 1);
    //   }
    // },
    removeSupplier(index, data) {
      console.log(data, "data");

      this.productCreate.suppliers.splice(index, 1);

      const _this = this;

      _this.formLoading = true;
      //  const param = data.id;

      if (this.productId > 0) {
        _this.$store
          .dispatch(PATCH, {
            url: "product/" + this.productId + "/inventory_supplier",
            data: {
              id: data.id,
            },
          })

          .then(() => {})
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
      //const param = data.id;
      /* ApiService.PUT(`product/${param}/inventory_supplier`, )
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			}); */

      // console.log(data, "data");
      // if (data.id) {
      //  // console.log(data.id,"dataid");
      // //	const param = { id: data.id };
      // 	// RemoveSuppliers(this.uuid, param).then(() => {
      // 	// 	this.suppliers.splice(index, 1);
      // 	// });

      // } else {
      // 	this.productCreate.suppliers.splice(index, 1);
      // }
    },
    discountPriceChange(index) {
      if (
        this.productCreate.priceList[index].discount_price >
        this.productCreate.priceList[index].selling_cost
      ) {
        this.productCreate.priceList[index].discount_price =
          this.productCreate.priceList[index].selling_cost;
      }
    },
    discountSelling(index) {
      if (
        this.productCreate.priceList[index].selling_cost <
        this.productCreate.priceList[index].discount_price
      ) {
        this.productCreate.priceList[index].selling_cost =
          this.productCreate.priceList[index].discount_price;
      }
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (
        this.productCreate &&
        this.productCreate.priceList &&
        this.productCreate.priceList.length < 1
      ) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.productCreate.priceList.push({
        id: null,
        unit_price: 0,
        selling_cost: 0,
        discount_price: 0,
        start_date: previous_end_date,
        end_date: null,
      });
    },

    removePrice(index) {
      if (index >= 1) {
        this.productCreate.priceList[index - 1].end_date = null;
      }
      this.productCreate.priceList.splice(index, 1);
    },

    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.productCreate.images = param;
    },
    updateAttachments(param) {
      this.productCreate.attachments = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          if (response.data.equipment_locations) {
            _this.equipment_locations = response.data.equipment_locations;
          }

          if (response.data.machine_types) {
            _this.machine_types = response.data.machine_types;
            // _this.machine_types = [];
            // response.data.machine_types.forEach(element => {
            //   let { id, name, subcategory} = element;
            //   _this.machine_types.push({ id, name, subcategory});
            // });
            // console.log(_this.machine_types);
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
            /*      _this.categoryList.push({
              text: this.productCreateData.category,
            });
            console.log(this.productCreateData,'response.data.category') */
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          // if (
          //   _this.lodash.isEmpty(response.data) === false &&
          //   response.data.supplier
          // ) {
          //   _this.supplierList = response.data.supplier;
          // }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            /*  if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              _this.productCreate.description =
                _this.barcodeSetting.description;
            } */

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
    supplier_list() {
      return this.localDB("suppliers", []);
    },
    supplierList: () => {
      return function (id) {
        //console.log(id,'id');
        let _vendorList = cloneDeep(this.vendorList);
        // console.log(_vendorList,'_vendorList')
        //  console.log(this.productCreate.suppliers,'111')
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(
                this.productCreate.suppliers,
                (inner_row) => inner_row.supplier_id == row.value
              )
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },

    isStartDateDisable: () => {
      return function (index) {
        if (this.productCreate.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.productCreate.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.productCreate.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },

    //   isDisable() {
    // 	const exists =  this.priceList.find((row) => {
    // 		return !row.start_date;
    // 	});
    // 	return exists ? true : false;
    // },
  },
  mounted() {
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
      //console.log(this.$store.state.config.localDB['data']['suppliers'],"2222");
    });

    this.getOptions();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
